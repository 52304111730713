module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WQG8Z75","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-99991864-7","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","zh","ja"],"defaultLanguage":"en","redirect":false,"siteUrl":"https://docs.pingcap.com","i18nextOptions":{"interpolation":{"escapeValue":false},"nsSeparator":false},"pages":[{"matchPath":"/:lang?/404","getLanguageFromPath":true},{"matchPath":"/:lang?/(tidb|tidb-data-migration|tidb-in-kubernetes|tidbcloud)/(.*)","getLanguageFromPath":true},{"matchPath":"/:lang?/","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"remarkPlugins":[null],"rehypePlugins":[[null,{"strict":"ignore"}]],"gatsbyRemarkPlugins":[{"resolve":"/Users/huangran/Projects/pingcap-repos/website-docs/node_modules/gatsby-remark-autolink-headers","id":"2d4c633d-7131-5422-82a7-a4ef59117634","name":"gatsby-remark-autolink-headers","version":"5.21.0","modulePath":"/Users/huangran/Projects/pingcap-repos/website-docs/node_modules/gatsby-remark-autolink-headers/index.js","pluginOptions":{"plugins":[],"offsetY":"100","enableCustomId":true},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onInitialClientRender","shouldUpdateScroll"],"ssrAPIs":["onRenderBody"]},{"resolve":"/Users/huangran/Projects/pingcap-repos/website-docs/gatsby/plugin/syntax-diagram","id":"0427b8fd-d5c2-5092-9011-b706268a96b1","version":"0.0.1","modulePath":"/Users/huangran/Projects/pingcap-repos/website-docs/gatsby/plugin/syntax-diagram/index.ts","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/Users/huangran/Projects/pingcap-repos/website-docs/gatsby/plugin/content","id":"0427b8fd-d5c2-5092-9011-b706268a96b1","version":"0.0.1","modulePath":"/Users/huangran/Projects/pingcap-repos/website-docs/gatsby/plugin/content/index.ts","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/huangran/Projects/pingcap-repos/website-docs","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":"100","enableCustomId":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
